import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import IStrapiImage from "../../interfaces/IStrapiImage";
import CTAWithImage from "../CTAWithImage";

const Wrapper = styled.div`
  padding: 60px 27px 0 27px;

  @media ${media.tablet} {
    padding: 100px 27px 0 27px;
  }

  @media ${media.tablet} {
    padding: 100px 125px 0 125px;
  }
`;

interface Props {
  image: IStrapiImage;
  imageDesktop: IStrapiImage;
  buttonText: string;
}

const CaseStudyApply: React.FC<Props> = (props) => {
  const { image, imageDesktop, buttonText } = props;

  if (!image || !imageDesktop) return null;

  return (
    <Wrapper>
      <CTAWithImage
        image={image}
        imageDesktop={imageDesktop}
        text={buttonText}
        width={321}
        height={68}
        desktopWidth={701}
        desktopHeight={65}
      />
    </Wrapper>
  );
};

export default CaseStudyApply;
