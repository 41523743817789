import React, { useState, useRef, useEffect, useMemo } from "react";
import BackArrow from "../../assets/svg/BackArrow";
import useWindowSize from "../../hooks/windowSize";
import SwipeTarget from "../SwipeTarget";
import {
  Constraint,
  SimpleSliderArrow,
  SimpleSliderButton,
  SimpleSliderDot,
  SimpleSliderDots,
  Wrapper,
} from "./styles";
import useUpdate from "../../hooks/useUpdate";

interface IProps {
  items: any;
  renderItem: Function;
  dots?: boolean;
  alternativeDots?: boolean;
  padding?: number;
  paddingDesktop?: number;
  dotsMargin?: number;
  dotsMarginDesktop?: number;
  keepDots?: boolean;
  formatItems?: boolean;
  interactionCallback?: Function;
  centerOnDesktopBelowItemCount?: number;
}

const SimpleSlider: React.FC<IProps> = (props) => {
  const {
    items,
    renderItem,
    padding,
    paddingDesktop,
    dots,
    dotsMargin,
    dotsMarginDesktop,
    keepDots,
    alternativeDots,
    formatItems,
    interactionCallback,
    centerOnDesktopBelowItemCount,
  } = props;

  const constraintRef = useRef(null);
  const wrapperRef = useRef(null);

  const [constranintSize, setConstraintSize] = useState<number | null>(null);

  const [childSize, setChildSize] = useState<number | null>(null);

  const [visibleChildren, setVisibleChildren] = useState(1);

  const [index, setIndex] = useState(0);

  const [transform, setTransform] = useState(0);

  const renderedItems = useMemo(() => {
    return items.map((item: any) => {
      return renderItem(item);
    });
  }, []);

  const { width } = useWindowSize();

  useEffect(() => {
    if (!constraintRef.current || !wrapperRef.current) return;

    const element = constraintRef.current as Element;

    const newConstraintWidth = element.getBoundingClientRect().width;

    const wrapperElement = wrapperRef.current as Element;

    if (!wrapperElement.firstElementChild) return;

    const newChildSize = wrapperElement.firstElementChild.getBoundingClientRect()
      .width;

    setChildSize(newChildSize);

    setConstraintSize(newConstraintWidth);
  }, [constraintRef, wrapperRef, width]);

  useEffect(() => {
    if (!childSize || !constranintSize) return;

    const currentPadding = width < 1025 ? padding || 0 : paddingDesktop || 0;

    const maxTransform =
      items.length * childSize + currentPadding * 2 - constranintSize;

    setTransform(Math.max(Math.min(index * childSize, maxTransform), 0));
  }, [index, childSize, constranintSize, width]);

  useUpdate(() => {
    if (!childSize || !constranintSize) return;

    setVisibleChildren(Math.floor(constranintSize / childSize));
  }, [childSize, constranintSize]);

  useUpdate(() => {
    if (index <= items.length - visibleChildren) return;

    setIndex(indexBoundry(items.length - visibleChildren));
  }, [visibleChildren]);

  function indexBoundry(newIndex: number) {
    return Math.max(0, Math.min(items.length - visibleChildren, newIndex));
  }

  function changeIndex(index: number) {
    interactionCallback && interactionCallback();

    setIndex(index);
  }

  return (
    <>
      <SwipeTarget
        swipeLeft={() => changeIndex(indexBoundry(index + 1))}
        swipeRight={() => changeIndex(indexBoundry(index - 1))}
        renderContent={(props: any) => {
          return (
            <Constraint
              ref={constraintRef}
              {...props}
              padding={padding}
              paddingDesktop={paddingDesktop}
            >
              <Wrapper
                ref={wrapperRef}
                style={{ transform: `translate(-${transform}px)` }}
                formatItems={formatItems}
                centerOnDesktop={
                  items.length < (centerOnDesktopBelowItemCount || 3)
                }
              >
                {renderedItems}
              </Wrapper>
            </Constraint>
          );
        }}
      />
      {dots && (
        <SimpleSliderDots
          keepDots={keepDots}
          dotsMargin={dotsMargin}
          dotsMarginDesktop={dotsMarginDesktop}
          hide={visibleChildren >= items.length}
        >
          <SimpleSliderButton
            onClick={() => changeIndex(indexBoundry(index - 1))}
          >
            <SimpleSliderArrow>
              <BackArrow />
            </SimpleSliderArrow>
          </SimpleSliderButton>
          {items.map((item: any, i: any) => {
            return (
              <SimpleSliderDot
                key={i}
                alternativeDots={alternativeDots}
                active={i === index}
                onClick={() => changeIndex(i)}
                hide={i + visibleChildren > items.length}
              />
            );
          })}
          <SimpleSliderButton
            last={true}
            onClick={() => changeIndex(indexBoundry(index + 1))}
          >
            <SimpleSliderArrow>
              <BackArrow />
            </SimpleSliderArrow>
          </SimpleSliderButton>
        </SimpleSliderDots>
      )}
    </>
  );
};

export default SimpleSlider;
