import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import CaseStudyWrapper from "../components/CaseStudyWrapper";
import CaseStudyRibbon from "../components/CaseStudyRibbon";
import ICaseStudyDetails from "../interfaces/ICaseStudyDetails";
import CaseStudyPrimary from "../components/CaseStudyPrimary";
import CaseStudyContent from "../components/CaseStudyContent";
import CaseStudySecondaryQuote from "../components/CaseStudySecondaryQuote";
import CaseStudyApply from "../components/CaseStudyApply";
import ICaseStudyPage from "../interfaces/ICaseStudyPage";
import ICompanyDetails from "../interfaces/ICompanyDetails";

import "../fragments/CaseStudy";
import CaseStudyOther from "../components/CaseStudyOther";
import normalizeCaseStudies from "../common/normalizeCaseStudies";
import CaseStudyHero from "../components/CaseStudyHero";
import CaseStudySecondary from "../components/CaseStudySecondary/idnex";
import CaseStudySEO from "../components/CaseStudySEO";
import ICaseStudyContent from "../interfaces/ICaseStudyContent";

interface IProps {
  data: any;
}

export const CaseStudyPage: React.FC<IProps> = (props) => {
  const { data: unformattedData } = props;

  const {
    caseStudy: unformattedCaseStudy,
    caseStudyPage,
    company,
  }: {
    caseStudy: ICaseStudyDetails;
    caseStudyPage: ICaseStudyPage;
    company: ICompanyDetails;
  } = unformattedData;

  const caseStudy = normalizeCaseStudies([unformattedCaseStudy])[0];

  const {
    slug,
    heading,
    primaryQuestions: unformattedPrimaryQuestions,
  } = caseStudy;

  const { ctaImage, ctaImageDesktop, ctaText } = caseStudyPage;

  const { name: companyName, caseStudies: unformattedCompanyCaseStudies } =
    company || {};

  const companyCaseStudies = normalizeCaseStudies(
    unformattedCompanyCaseStudies
  );

  const otherCaseStudies = companyCaseStudies.filter(
    (caseStudy) => caseStudy.slug !== slug
  );

  const primaryQuestions = unformattedPrimaryQuestions
    .map((item: ICaseStudyContent) => {
      const { question } = item;

      return {
        ...item,
        question: question.replace("$company", companyName || "$company"),
      };
    })
    .map((item: ICaseStudyContent) => {
      const { question } = item;

      return {
        ...item,
        question: question.replace("at $company", ""),
      };
    });

  return (
    <Layout
      headerLocation="success"
      skipFooterBorder={otherCaseStudies.length > 0}
    >
      <CaseStudySEO caseStudy={caseStudy} />
      <CaseStudyWrapper hasPadding={otherCaseStudies.length === 0}>
        <CaseStudyRibbon text={heading} />
        <CaseStudyHero company={company} caseStudy={caseStudy} />
        <CaseStudyPrimary>
          <CaseStudyContent items={primaryQuestions} />
        </CaseStudyPrimary>
        <CaseStudyApply
          image={ctaImage}
          imageDesktop={ctaImageDesktop}
          buttonText={ctaText}
        />
        <CaseStudyOther items={otherCaseStudies} />
      </CaseStudyWrapper>
    </Layout>
  );
};

export default CaseStudyPage;

export const pageQuery = graphql`
  query CustomCaseStudyQuery($slug: String!, $companyId: String) {
    caseStudyPage: strapiCaseStudyPage {
      ctaText
      ctaImage {
        alternativeText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 321
              height: 68
              quality: 100
              placeholder: NONE
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
      }
      ctaImageDesktop {
        alternativeText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 701
              height: 65
              quality: 100
              placeholder: NONE
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
      }
    }
    company: strapiCompanies(strapiId: { eq: $companyId }) {
      name
      links {
        label
        url
      }
      caseStudies {
        ...CaseStudy
      }
    }
    caseStudy: strapiCasestudies(slug: { eq: $slug }) {
      ...CaseStudy
    }
  }
`;
