import React, { MouseEventHandler } from "react";
import ReactPlayer from "react-player/file";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 12;
  background: rgba(0, 0, 0, 0.2);
  padding: 27px;
`;

const VideoWrapper = styled.div`
  width: 100%;
  max-width: 900px;
`;

const Close = styled.button`
  position: absolute;
  top: 20px;
  right: 27px;
  width: 46px;
  height: 46px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

type Props = {
  src: string;
  onClose: MouseEventHandler<HTMLElement>;
};

const VideoPlayer = (props: Props) => {
  const { src, onClose } = props;

  return (
    <Wrapper onClick={onClose}>
      <VideoWrapper onClick={(e) => e.stopPropagation()}>
        <ReactPlayer
          url={src}
          playing={true}
          width="100%"
          height="auto "
          controls={true}
          pip={false}
        />
      </VideoWrapper>
      <Close onClick={onClose}>
        <svg width="24" height="24" fill="#fff" viewBox="0 0 12 12">
          <path
            fillRule="evenodd"
            d="M11.53.47a.75.75 0 0 0-1.061 0l-4.47 4.47L1.529.47A.75.75 0 1 0 .468 1.531l4.47 4.47-4.47 4.47a.75.75 0 1 0 1.061 1.061l4.47-4.47 4.47 4.47a.75.75 0 1 0 1.061-1.061l-4.47-4.47 4.47-4.47a.75.75 0 0 0 0-1.061z"
          />
        </svg>
      </Close>
    </Wrapper>
  );
};

export default VideoPlayer;
