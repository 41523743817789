import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";

const Wrapper = styled.div`
  padding-top: 10px;

  @media ${media.desktop} {
    padding-top: 12px;
  }
`;

interface Props {}

const CaseStudyPrimary: React.FC<Props> = (props) => {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default CaseStudyPrimary;
