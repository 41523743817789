import React from "react";
import { Typo32 } from "../Typography";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import Ribbon from "../../assets/svg/Ribbon";
import BackButton from "../BackButton";

const Container = styled.div`
  display: flex;
  padding-right: 27px;
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  padding: 0 43px 0 27px;
  background: linear-gradient(
    91.45deg,
    rgba(236, 94, 115, 0.25) 0%,
    rgba(248, 125, 248, 0.25) 43.02%,
    rgba(116, 143, 248, 0.25) 75.49%,
    rgba(99, 181, 245, 0.25) 100%
  );
  overflow: hidden;
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    letter-spacing: 0;
    padding: 0 69px 0 27px;
  }

  @media ${media.desktop} {
    padding: 0 69px 0 125px;
  }

  @media (min-width: 1555px) {
    padding-left: calc(50vw - 655px);
  }
`;

const Content = styled.div`
  display: flex;
`;

const Text = styled.div`
  padding: 10px 0;
  margin-left: 14px;

  @media ${media.tablet} {
    padding: 12px 0 14px 0;
    margin-left: 60px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  right: -1px;
  top: 0;
  height: 100%;
`;

interface Props {
  text: string;
}

const CaseStudyRibbon: React.FC<Props> = (props) => {
  const { text } = props;

  return (
    <Container>
      <Wrapper>
        <Content>
          <BackButton to={"/success-stories/"} />
          <Text>
            <Typo32
              as="h1"
              fontWeight="700"
              color="#1B1B1B"
            >
              {text}
            </Typo32>
          </Text>
        </Content>
        <Overlay>
          <Ribbon />
        </Overlay>
      </Wrapper>
    </Container>
  );
};

export default CaseStudyRibbon;
