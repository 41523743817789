import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const Ribbon: SvgComponent = (props) => (
  <svg
    width="34"
    height="68"
    viewBox="0 0 34 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: "100%", width: "auto" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0243 68H33.9996V0H24.0243C27.6319 0 29.396 4.39944 26.7879 6.89184L1.4474 31.1082C-0.201218 32.6836 -0.201218 35.3164 1.4474 36.8918L26.7879 61.1082C29.396 63.6006 27.6318 68 24.0243 68Z"
      fill="white"
    />
  </svg>
);

export default Ribbon;
