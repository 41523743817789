import React from "react";
import ICaseStudy from "../../interfaces/ICaseStudy";
import CaseStudiesSlider from "../CaseStudiesSlider";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";

const Wrapper = styled.div`
  margin-top: 60px;
  padding: 40px 0 30px 0;
  background: rgba(240, 245, 255, 0.6);

  @media ${media.desktop} {
    margin-top: 70px;
    padding: 63px 0 90px 0;
  }
`;

const Content = styled.div`
  @media ${media.desktop} {
    width: 100%;
    padding: 0 92px;
    max-width: 1554px;
    margin-left: auto;
    margin-right: auto;
  }
`;

interface Props {
  items: ICaseStudy[];
}

const CaseStudyOther: React.FC<Props> = (props) => {
  const { items } = props;

  if (items.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Content>
        <CaseStudiesSlider paddingDesktop={0} items={items} />
      </Content>
    </Wrapper>
  );
};

export default CaseStudyOther;
