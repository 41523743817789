import React from "react";
import getCaseStudySchema from "../../common/caseStudySchema";
import ICaseStudyDetails from "../../interfaces/ICaseStudyDetails";
import SEO from "../Seo";

type Props = {
  caseStudy: ICaseStudyDetails;
};

const CaseStudySEO: React.FC<Props> = (props) => {
  const { caseStudy } = props;

  const {
    heading,
    seo,
    leadQuote,
    opengraphImage,
    slug,
    createdAt,
    updatedAt,
    primaryQuestions,
    secondaryQuestions,
    secondaryQuote,
  } = caseStudy;

  const { title, description, image } = seo;

  const seoImagePath =
    image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src ||
    null;

  const fallbackImagePath =
    opengraphImage?.localFile?.childImageSharp?.gatsbyImageData?.images
      ?.fallback?.src;

  const imagePath = seoImagePath || fallbackImagePath || null;

  const imageUrl = imagePath
    ? `https://${process.env.GATSBY_PUBLIC_DOMAIN}${imagePath}`
    : "";

  const schema = getCaseStudySchema(caseStudy);

  schema.articleBody = `
        ${leadQuote} 
        ${primaryQuestions.map(
          ({ question, answer }) => `${question} ${answer}`
        )}
        ${secondaryQuote}
        ${secondaryQuestions.map(
          ({ question, answer }) => `${question} ${answer}`
        )}
    `;

  return (
    <SEO
      title={title || `${heading} | Flatworld.co Success Story` || ""}
      description={description || leadQuote}
      image={imageUrl}
      url={`/success-story/${slug}/`}
      schemaMarkup={schema}
    />
  );
};

export default CaseStudySEO;
