import ga4Event from '../lib/ga4-event';
import uaEvent from '../lib/ua-event';

export interface IAEvent {
  ec: string;
  ea: string;
  el: string;
  ev?: string;
  ni?: boolean;
}

export default function aEvent(event: IAEvent) {
  const { ec: eventCategory, ea: eventAction, ev: eventValue = '', el: eventLabel } = event;

  uaEvent(event);

  const name = formatUA(eventAction);
  const label = formatUA(eventLabel);
  const value = formatUA(eventValue);
  const category = formatUA(eventCategory);

  if (!name) return;

  ga4Event(name, {
    label,
    value,
    category,
  });
}

function formatUA(value: string) {
  if (!value) return undefined;

  return value.replace(/-/g, '_').replace(/\s+/g, '_');
}
