import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";

interface IWrapper {
  hasPadding?: boolean;
}

const Wrapper = styled.div<IWrapper>`
    width: 100%; 
    padding-top: 22px; 
    overflow: hidden; 
    ${({ hasPadding }) => (hasPadding ? "padding-bottom: 60px;" : "")}

    @media ${media.desktop}{
        padding-top: 30px;
        max-width: 1560px
        margin: auto;
    }
`;

interface Props {
  hasPadding?: boolean;
}

const CaseStudyWrapper: React.FC<Props> = (props) => {
  const { children, hasPadding } = props;

  return <Wrapper hasPadding={hasPadding}>{children}</Wrapper>;
};

export default CaseStudyWrapper;
