import React, { useState } from "react";

interface IProps {
  renderContent: Function;
  swipeLeft: Function;
  swipeRight: Function;
}

const SwipeTarget: React.FC<IProps> = ({
  renderContent,
  swipeLeft,
  swipeRight,
}) => {
  const [started, setStarted] = useState(false);
  const [startX, setStartX] = useState(0);

  function onTouchStart(e: TouchEvent) {
    const x = e.touches[0].clientX;

    setStarted(true);
    setStartX(x);
  }

  function onTouchEnd(e: TouchEvent) {
    if (!started) return;

    setStarted(false);

    const endX = e.changedTouches[0].clientX;

    const move = endX - startX;

    const dist = Math.abs(move);

    if (dist < 40) return;

    if (move > 0) {
      swipeRight();
      return;
    }

    swipeLeft();
  }

  return renderContent({ onTouchStart, onTouchEnd });
};

export default SwipeTarget;
