import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import { Link } from "gatsby";
import IStrapiImage from "../../interfaces/IStrapiImage";
import StrapiImage from "../StrapiImage";

interface ISize {
  width: number;
  height: number;
  desktopWidth: number;
  desktopHeight: number;
}

const ImageWidth = styled.div<ISize>`
  width: 100%;
  max-width: ${({ width }) => width}px;
  margin: auto;

  @media ${media.tablet} {
    max-width: ${({ desktopWidth }) => desktopWidth}px;
  }
`;

const Padding = styled.div<ISize>`
  height: 0;
  padding-bottom: ${({ width, height }) => (height / width) * 100}%;
  position: relative;
  overflow: hidden;

  @media ${media.tablet} {
    padding-bottom: ${({ desktopWidth, desktopHeight }) =>
      (desktopHeight / desktopWidth) * 100}%;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Button = styled.p`
  display: block;
  width: 100%;
  max-width: 565px;
  margin: 10px auto 0 auto;
  padding: 15px 13px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.watermelon};
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  align-items: center;
  letter-spacing: -0.01em;
  text-align: center;

  &:hover {
    background: ${({ theme }) => theme.colors.salmon};
  }

  @media ${media.tablet} {
    margin-top: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
`;

type Props = {
  image: IStrapiImage;
  imageDesktop: IStrapiImage;
  text: string;
  width: number;
  desktopWidth: number;
  height: number;
  desktopHeight: number;
};

const CTAWithImage: React.FC<Props> = (props) => {
  const {
    image,
    imageDesktop,
    text,
    width,
    height,
    desktopWidth,
    desktopHeight,
  } = props;

  return (
    <>
      <ImageWidth
        width={width}
        height={height}
        desktopWidth={desktopWidth}
        desktopHeight={desktopHeight}
      >
        {image && imageDesktop && (
          <Padding
            width={width}
            height={height}
            desktopWidth={desktopWidth}
            desktopHeight={desktopHeight}
          >
            <ImageWrapper>
              <StrapiImage image={[image, imageDesktop]} />
            </ImageWrapper>
          </Padding>
        )}
      </ImageWidth>
      <Link to="/jobs/">
        <Button>{text}</Button>
      </Link>
    </>
  );
};

export default CTAWithImage;
