import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const BackArrow: SvgComponent = (props) => (
  <svg
    width="23"
    height="12"
    viewBox="0 0 23 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.35355 1.60355C6.54882 1.40829 6.54882 1.09171 6.35355 0.896447C6.15829 0.701184 5.84171 0.701184 5.64645 0.896447L6.35355 1.60355ZM1 6.25L0.646447 5.89645L0.292893 6.25L0.646447 6.60355L1 6.25ZM5.64645 11.6036C5.84171 11.7988 6.15829 11.7988 6.35355 11.6036C6.54882 11.4083 6.54882 11.0917 6.35355 10.8964L5.64645 11.6036ZM5.64645 0.896447L0.646447 5.89645L1.35355 6.60355L6.35355 1.60355L5.64645 0.896447ZM0.646447 6.60355L5.64645 11.6036L6.35355 10.8964L1.35355 5.89645L0.646447 6.60355Z"
      fill="#0B264C"
    />
    <path d="M22 6.25H1" stroke="#0B264C" strokeLinecap="round" />
  </svg>
);

export default BackArrow;
