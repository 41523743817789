import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import ICaseStudyDetails from "../../interfaces/ICaseStudyDetails";
import ICompany from "../../interfaces/ICompany";
import CaseStudyFeatured from "../CaseStudyFeatured";
import CaseStudyLeadQuote from "../CaseStudyLeadQuote";
import CaseStudyMedia from "../CaseStudyMedia";

const Wrapper = styled.div`
  padding: 20px 27px 0 27px;

  @media ${media.phone} {
    display: flex;
    padding: 40px 27px 0 27px;
    width: 100%;
    max-width: 1554px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${media.desktop} {
    padding: 40px 125px 0 125px;
  }
`;

const MobileQuoteWrapper = styled.div`
  @media ${media.phone} {
    display: none;
  }
`;

const DesktopQuoteWrapper = styled.div`
  display: none;

  @media ${media.phone} {
    display: block;
  }

  @media ${media.desktop} {
    padding-top: 30px;
  }
`;

const ImageWrapper = styled.div`
  padding-top: 25px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;

  & img {
    border-radius: 10px;
  }

  @media ${media.phone} {
    width: 320px;
    max-width: none;
    padding-top: 0;
    margin-right: 30px;
    margin-left: 0;
    order: 1;
    flex-shrink: 0;
    border-radius: 21px;

    & img {
      border-radius: 21px;
    }
  }
`;

const InfoWrapper = styled.div`
  @media ${media.phone} {
    order: 2;
    margin-left: 29px;
    flex-grow: 1;
  }

  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
  }
`;

interface Props {
  caseStudy: ICaseStudyDetails;
  company: ICompany;
}

const CaseStudyHero: React.FC<Props> = (props) => {
  const { caseStudy, company } = props;

  const { video, heroImage, heroImageDesktop, leadQuote } = caseStudy;

  return (
    <Wrapper>
      <MobileQuoteWrapper>
        <CaseStudyLeadQuote text={leadQuote} />
      </MobileQuoteWrapper>
      <ImageWrapper>
        <CaseStudyMedia image={heroImage} video={video} />
      </ImageWrapper>
      <InfoWrapper>
        <DesktopQuoteWrapper>
          <CaseStudyLeadQuote text={leadQuote} />
        </DesktopQuoteWrapper>
        <CaseStudyFeatured company={company} caseStudy={caseStudy} />
      </InfoWrapper>
    </Wrapper>
  );
};

export default CaseStudyHero;
