import styled, { css } from 'styled-components';
import { media } from '../../common/MediaQueries';

interface IConstraint {
  padding?: number;
  paddingDesktop?: number;
}

export const Constraint = styled.div<IConstraint>`
  width: 100%;
  overflow-x: hidden;

  ${({ padding }) => (typeof padding !== 'undefined' ? `padding: 0 ${padding}px` : '')};

  @media ${media.desktop} {
    ${({ paddingDesktop }) =>
      typeof paddingDesktop !== 'undefined' ? `padding: 0 ${paddingDesktop}px` : ''};
  }
`;

interface IWrapper {
  formatItems?: boolean;
  centerOnDesktop?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  display: flex;
  width: 100%;
  position: relative;
  transition: transform 60ms cubic-bezier(0.5, 1, 0.89, 1);
  will-change: transform;

  ${({ formatItems }) =>
    formatItems &&
    css`
      @media ${media.phone} {
        & > :first-child:last-child,
        & > :first-child:nth-last-child(2),
        & > :last-child:nth-child(2) {
          max-width: 40%;
        }
      }

      @media ${media.tablet} {
        & > :first-child:last-child,
        & > :first-child:nth-last-child(2),
        & > :last-child:nth-child(2) {
          max-width: 33.333%;
        }
      }
    `}

  ${({ centerOnDesktop }) =>
    centerOnDesktop &&
    css`
      @media ${media.phone} {
        justify-content: center;
      }
    `}
`;

interface ISimpleSliderDots {
  dotsMargin?: number;
  dotsMarginDesktop?: number;
  keepDots?: boolean;
  hide?: boolean;
}

export const SimpleSliderDots = styled.div<ISimpleSliderDots>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  margin-top: ${({ dotsMargin }) => (dotsMargin ? dotsMargin : '26')}px;

  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}

  @media ${media.tablet} {
    ${({ keepDots }) => !keepDots && 'display: none;'}

    ${({ dotsMarginDesktop }) =>
      typeof dotsMarginDesktop !== 'undefined' && `margin-top: ${dotsMarginDesktop}px;`}
  }
`;

interface IDot {
  active: boolean;
  alternativeDots?: boolean;
  hide?: boolean;
}

export const SimpleSliderDot = styled.div<IDot>`
  width: 8px;
  height: 8px;
  background: #658ac8;
  border-radius: 50%;
  margin: 0 10px;
  ${({ active }) => (active ? '' : 'opacity: 0.2;')}

  ${({ hide }) => hide && 'display:none;'}

    @media ${media.tablet} {
    ${({ alternativeDots }) =>
      alternativeDots &&
      css`
        width: 12px;
        height: 12px;
        background: transparent;
        border: 2px solid #0b264c;
        margin: 0 18px;
      `}

    ${({ alternativeDots, active }) => alternativeDots && active && 'background: #0B264C;'}
  }
`;

interface ISimpleSliderButton {
  last?: boolean;
}

export const SimpleSliderButton = styled.button<ISimpleSliderButton>`
  display: none;

  @media ${media.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    cursor: pointer;

    ${({ last }) =>
      last
        ? css`
            transform: rotate(180deg);
            margin-left: 12px;
          `
        : css`
            margin-right: 12px;
          `}
  }
`;

export const SimpleSliderArrow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 8px;
`;
