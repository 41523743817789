import React, { useMemo, useState } from "react";
import IStrapiImage from "../../interfaces/IStrapiImage";
import StrapiImage from "../StrapiImage";
import styled, { keyframes } from "styled-components";
import { media } from "../../common/MediaQueries";
import VideoPlayer from "../VideoPlayer";
import aEvent from "../../common/aEvent";
import IVideo from "../../interfaces/IVideo";

const animation = keyframes`
    0% { 
        transform: rotate( 0deg );
    }

    100% { 
        transform: rotate( 360deg );
    }
`;

const Wrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  transform: translate(0, 0, 0.01px);

  @media ${media.tablet} {
    border-radius: 21px;
  }
`;

const Play = styled.div`
  width: 68px;
  height: 68px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 100ms ease-out;

  & svg {
    margin-left: 6px;
  }
`;

const PlayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover ${Play} {
    transform: scale(1.1, 1.1);
  }
`;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 12;
`;

const Loader = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-bottom: 0;
  border-left: 0;
  animation: ${animation} 2s infinite;
`;

interface Props {
  image: IStrapiImage | null;
  video?: IVideo;
}

const CaseStudyMedia: React.FC<Props> = (props) => {
  const { image, video } = props;

  const [show, setShow] = useState(false);

  if (!image) return null;

  const memoImage = useMemo(() => <StrapiImage image={image} />, []);

  const renderVideo =
    typeof window !== "undefined" && show && video && video.url ? true : false;

  return (
    <Wrapper>
      {memoImage}
      {video && (
        <PlayWrapper
          onClick={() => {
            aEvent({
              ec: "Content Events",
              ea: "drawer-open",
              el: video.name,
              ni: false,
            });

            setShow(true);
          }}
        >
          <Play>
            <svg
              width="22"
              height="26"
              viewBox="0 0 22 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.47454e-07 24.2476L1.83075e-06 1.75245C1.86459e-06 0.978148 0.842119 0.497613 1.50873 0.891523L20.543 12.1391C21.1981 12.5261 21.1981 13.4739 20.543 13.8609L1.50873 25.1085C0.842114 25.5024 8.13609e-07 25.0219 8.47454e-07 24.2476Z"
                fill="#0B264C"
              />
            </svg>
          </Play>
        </PlayWrapper>
      )}
      {renderVideo && video && (
        <React.Suspense
          fallback={
            <LoaderWrapper onClick={() => setShow(false)}>
              <Loader />
            </LoaderWrapper>
          }
        >
          <VideoPlayer
            onClose={() => setShow(false)}
            src={`${process.env.GATSBY_API_URL}${video.url}`}
          />
        </React.Suspense>
      )}
    </Wrapper>
  );
};

export default CaseStudyMedia;
