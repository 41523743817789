import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import { Typo31 } from "../Typography";

const Wrapper = styled.div`
  letter-spacing: -0.01em;
  font-weight: 500;
  text-align: center;
  font-style: italic;

  @media ${media.phone} {
    text-align: left;
    font-weight: 400;
    letter-spacing: -0.0028em;
    text-align: left;
  }

  @media ${media.desktop} {
    font-weight: 500;
    text-align: center;
  }
`;

interface Props {
  text: string;
}

const CaseStudyLeadQuote: React.FC<Props> = (props) => {
  const { text } = props;

  if (!text) return null;

  return (
    <Wrapper>
      <Typo31 color="rgba(11, 38, 76, .7)">{text}</Typo31>
    </Wrapper>
  );
};

export default CaseStudyLeadQuote;
