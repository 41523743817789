import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";
import ICaseStudyContent from "../../interfaces/ICaseStudyContent";
import { Typography } from "../HTMLContent/style";
import { Heading18, Typo14 } from "../Typography";

const Wrapper = styled.div`
  padding: 0 27px;

  @media ${media.desktop} {
    padding: 0 125px;
    max-width: 1554px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Item = styled.div`
  margin-top: 30px;

  @media ${media.desktop} {
    margin-top: 58px;
  }
`;

const Heading = styled.div`
  letter-spacing: -0.01em;

  @media ${media.desktop} {
    letter-spacing: -0.03em;
  }
`;

const Answer = styled.div`
  position: relative;
  margin-top: 6px;
  padding-left: 22px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #1b1b1b;
  font-familiy: "Montserrat", "Verdana", sans-serif;

  & :before {
    position: absolute;
    top: 8px;
    left: 0;
    display: block;
    content: " ";
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.watermelon};
  }

  @media ${media.tablet} {
    max-width: 773px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 22px;
    padding-left: 27px;

    & :before {
      top: 7px;
    }
  }
`;

interface Props {
  items: ICaseStudyContent[];
}

const CaseStudyContent: React.FC<Props> = (props) => {
  const { items } = props;

  if (items.length === 0) return null;

  return (
    <Wrapper>
      {items.map((item) => {
        const { uid, question, answer } = item;

        return (
          <Item key={uid}>
            <Heading>
              <Heading18 color="#658AC8" fontWeight="600">
                {question}
              </Heading18>
            </Heading>
            <Answer>
              <Typography dangerouslySetInnerHTML={{ __html: answer }} />
            </Answer>
          </Item>
        );
      })}
    </Wrapper>
  );
};

export default CaseStudyContent;
