import React from "react";
import ICompany from "../../interfaces/ICompany";
import styled from "styled-components";
import ICaseStudyDetails from "../../interfaces/ICaseStudyDetails";
import { Typo33, Typo38 } from "../Typography";
import { media } from "../../common/MediaQueries";

const Wrapper = styled.div`
  margin-top: 28px;
  padding: 8px 19px 13px 19px;
  background: #f0f5ff;
  border: 1px solid rgba(155, 192, 255, 0.38);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.05);
  border-radius: 15px;

  @media ${media.desktop} {
    display: flex;
    align-items: flex-start;
    margin-top: 63px;
    padding: 3px 39px 3px 39px;
  }
`;

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #d9e7ff;

  &:last-child {
    border-bottom: none;
  }

  @media ${media.desktop} {
    padding: 10px;
    flex-direction: column;
    align-items: center;
    border: 0;
    width: 0;
    flex-grow: 1;
  }
`;

const Heading = styled.div`
  font-variant: small-caps;
  margin-right: auto;
  text-transform: uppercase;
  letter-spacing: -0.01em;

  @media ${media.desktop} {
    margin: auto 0 7px 0;
    letter-spacing: 0;
  }
`;

const Text = styled.div`
  letter-spacing: -0.01em;

  @media ${media.desktop} {
    letter-spacing: -0.02em;
    text-align: center;
  }
`;

interface Props {
  caseStudy: ICaseStudyDetails;
  company: ICompany;
}

const CaseStudyFeatured: React.FC<Props> = (props) => {
  const { caseStudy, company } = props;

  const { position, featuredInfo } = caseStudy;

  const { name: companyName, links } = company || {};

  const companyWebsite = (links || []).filter(
    (link) => link.label?.toLowerCase() === "company website"
  )[0]?.url;

  const items = [
    { uid: "position", heading: "Position", value: position },
    { uid: "hired", heading: "Hired By", value: companyName },
  ].concat(featuredInfo);

  const validItems = items.filter((item) => item && item.heading && item.value);

  if (validItems.length === 0) return null;

  return (
    <Wrapper>
      {validItems.map((item) => {
        const { heading, value, uid } = item;

        const isLink = uid === "hired" && companyWebsite;

        return (
          <Item key={uid}>
            <Heading>
              <Typo33 color="#658AC8" fontWeight="600">
                {heading}
              </Typo33>
            </Heading>
            <Text>
              <Typo38
                color="#0B264C"
                fontWeight="500"
                as={isLink ? "a" : "p"}
                href={isLink ? companyWebsite : null}
                style={isLink ? { textDecoration: "underline" } : undefined}
                target={isLink ? "_blank" : undefined}
              >
                {value}
              </Typo38>
            </Text>
          </Item>
        );
      })}
    </Wrapper>
  );
};

export default CaseStudyFeatured;
