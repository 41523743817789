import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import BackArrow from "../../assets/svg/BackArrow";
import { media } from "../../common/MediaQueries";
import { Typo14 } from "../Typography";

const Back = styled.div`
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.85;
  }

  @media ${media.tablet} {
    height: 68px;
  }
`;

const Arrow = styled.div`
  height: 100%;
  width: 20px;
  height: 10px;
  display: flex;
  align-items: center;
  margin-top: -3px;

  @media ${media.tablet} {
    margin-top: 0;
  }
`;

const BackText = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    margin-left: 8px;
  }
`;

type Props = {
  to: string;
};

const BackButton: React.FC<Props> = (props) => {
  const { to } = props;

  return (
    <Link to={to}>
      <Back>
        <Arrow>
          <BackArrow />
        </Arrow>
        <BackText>
          <Typo14 fontWeight="500" color="#53637C">
            BACK
          </Typo14>
        </BackText>
      </Back>
    </Link>
  );
};

export default BackButton;
